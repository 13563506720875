@import "variables.scss";

html,
body,
#root {
    height: 100%;
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 16px;

    @media screen and (min-width: $tablet) {
        font-size: 18px;
    }
}

html {
    background-color: #eee;
    color: #212121;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Karla", "Rubik", "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body .heading {
    font-family: "Rubik", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
